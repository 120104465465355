
import kanban from "@/components/dossier/kanban.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        kanban,
    },
})
export default class Dossiers extends Vue {}
