
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import { WorkStatus } from "@/store/modules/activity.store";
  import vSelect from "vue-select";
  import FormDateRangePicker from "@/components/forms/date-range.vue";
  import OpenIndicator from "@/assets/svg/heroicons/selector.svg";
  import WonLostChart from "@/components/sales-pipeline/won-lost-chart.vue";
  import { formatCurrency } from "@/utils/formatters";
  import { HasKanban, KanbanType } from "@/mixins/has-kanban";
  import { getActivityCommission } from "@/utils/model-helpers";
  import type { DealCard } from "@/components/sales-pipeline/deal-card.vue";

  vSelect.props.components.default = () => ({ OpenIndicator });

  interface DealColumn {
    canAdd?: boolean;
    code: WorkStatus | "OPEN";
    deals: DealCard[];
    label?: string;
  }

  @Component({
    components: {
      vSelect,
      FormDateRangePicker,

      userSelect: () => import("@/components/forms/user-select.vue"),
      WonLostChart: () => import("@/components/sales-pipeline/won-lost-chart.vue"),
      userImage: () => import("@/components/general/user-image.vue"),
      userImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
      dossierCard: () => import("@/components/dossier/dossier-card.vue"),
      dossierList: () => import("@/components/dossier/list.vue"),
      GeneralHeaderBar: () => import("@/components/general/header-bar.vue"),
    },
  })
  export default class DossierKanban extends Mixins(HasKanban) {
    formatCurrency = formatCurrency;

    kanbanType: KanbanType = "dossiers";

    initialWorkStatuses: WorkStatus[] = [
      WorkStatus.newDossier,
      WorkStatus.startUp,
      WorkStatus.published,
      WorkStatus.firstOffer,
      WorkStatus.sold,
      WorkStatus.done,
      WorkStatus.archived,
      WorkStatus.onHold,
    ];

    rangeSubjects = [
      { value: "dossier_started_at", label: "Nieuw", default: true },
      { value: "dossier_startup_at", label: "Opstart" },
      { value: "dossier_online_at", label: "Online" },
      { value: "dossier_option_at", label: "In optie" },
      { value: "dossier_sold_at", label: "Verkocht" },
      { value: "dossier_out_bo_at", label: "Uit mandaat" },
    ];

    get closedBudget() {
      return this.localActivities
        .filter((a) => [WorkStatus.sold, WorkStatus.done].includes(a.work_status))
        .reduce((accumulator, activity) => {
          return accumulator + getActivityCommission(activity).commission;
        }, 0);
    }

    get openBudget() {
      return this.localActivities
        .filter((a) => [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer].includes(a.work_status))
        .reduce((accumulator, activity) => {
          return accumulator + getActivityCommission(activity).commission;
        }, 0);
    }

    get columns(): DealColumn[] {
      if (this.displayMode === "unresolved") {
        return [
          { code: WorkStatus.newDossier, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.newDossier) },
          { code: WorkStatus.startUp, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.startUp) },
          { code: WorkStatus.published, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.published) },
          { code: WorkStatus.firstOffer, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.firstOffer) },
          { code: WorkStatus.sold, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.sold) },
          { code: WorkStatus.done, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.done) },
          { code: WorkStatus.archived, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.archived) },
        ];
      }

      return [
        { code: WorkStatus.sold, deals: this.localActivities.filter((a) => [WorkStatus.sold, WorkStatus.done].includes(a.work_status)) },
        { code: "OPEN", deals: this.localActivities.filter((a) => [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer].includes(a.work_status)) },
      ];
    }

    get openClosedData(): { closed: { total: number; percent: number; budget: number }; open: { total: number; percent: number; budget: number } } {
      const closed = this.localActivities.filter((a) => [WorkStatus.sold, WorkStatus.done, WorkStatus.archived].includes(a.work_status));
      const open = this.localActivities.filter((a) => [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer].includes(a.work_status));
      const total = closed.length + open.length;

      return {
        closed: {
          total: closed.length,
          percent: total ? Math.round((closed.length / total) * 100) : 0,
          budget: this.closedBudget,
        },
        open: {
          total: open.length,
          percent: total ? Math.round((open.length / total) * 100) : 0,
          budget: this.openBudget,
        },
      };
    }

    /**
     * @name Change the display mode
     * @description Change the display mode of the kanban board and set the router parameter for the new mode
     *
     * @param mode The new display mode
     */
    handleChangeDisplayMode(mode: "resolved" | "unresolved" | "list") {
      this.displayMode = mode;

      this.$router.push({ query: this.$route.query, hash: mode });
    }

    @Watch("displayMode")
    handleDisplayModeChange(newMode: "resolved" | "unresolved" | "list") {
      if (newMode === "unresolved" && (this.filter.range.to || this.filter.range.from)) {
        this.filter.range.to = "";
        this.filter.range.from = "";
      }
    }
  }
